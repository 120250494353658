import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import Layout from './Layout/Layout';

import Home from './Pages/Home';
import NoPage from './Pages/NoPage';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Projects from './Pages/Project';

const router = createBrowserRouter([
  {
    element: <Layout/>,
    children: [
      {
        path: '/',
        element: <Home/>
      },
      {
        path: '/contact',
        element: <Contact/>
      },
      {
        path: '/about',
        element: <About/>
      },
      {
        path: '/projects',
        element: <Projects/>
      },
      {
        path: '/*',
        element: <NoPage/>
      }
    ]
  }
])
export default function App() {
  return (
    <div>
      <RouterProvider router={router}/>
    </div>
  );
}