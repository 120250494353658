import { useState } from 'react';
import { FaLinkedin, FaSquareInstagram, FaSquareFacebook } from "react-icons/fa6";

export default function Contact() {
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        message: '',
        selectedObject: '',
    });


    const [formErrors, setFormErrors] = useState({});
    const [isFormSent, setIsFormSent] = useState(false); // State for success message

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: null }); // Clear errors when the user starts typing
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Example of simple validation
        const errors = {};

        if (!formData.email.match(/^\S+@\S+\.\S+$/)) {
            errors.email = 'Invalid email address';
        }

        if (!formData.selectedObject) {
            errors.selectedObject = 'Please select an object';
        }
        // Add more validation rules as needed

        if (Object.keys(errors).length === 0) {
            // Form submission logic (replace with your actual submission code)
            console.log('Form submitted:', formData);

            // Update state to display success message
            setIsFormSent(true);

            // Reset form data after submission
            setFormData({
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                message: '',
                selectedObject: '',
            });
        } else {
            // Update state to display errors
            setFormErrors(errors);
        }
    };

    return (
        <div className="flex lg:flex-row flex-col justify-center lg:w-[70vw] md:w-[90vw] w-full mx-auto my-8">
            {/* Contact Information Section */}
            <section className="lg:w-1/2 flex flex-col justify-between  bg-white p-6 m-4 rounded-lg shadow-2xl">
                <div>
                    <h2 className="text-2xl font-semibold mb-6">Contact Information</h2>
                    <div className="flex gap-3 mb-2">
                        <strong>Email:</strong>
                        <p><a href="mailto:Robin@vikstedt.se">Robin@vikstedt.se</a></p>
                    </div>
                    <div className="flex gap-3 mb-2">
                        <strong>Phone:</strong>
                        <p><a href="tel:+460703373939">+46 070 - 337 39 39</a></p>
                    </div>                    
                </div>

                <div className='my-10'>
                    <h2 className="text-xl font-semibold mb-6">Follow me :</h2>
                    <ul className="list-none flex gap-4">
                        <li className="inline-block ">
                            <a href="https://www.facebook.com/profile.php?id=100068941629133" className=" text-gray-400 hover:text-gray-600 ">
                                <FaSquareFacebook className="h-10 w-10" />
                            </a>
                        </li>
                        <li className="inline-block ">
                            <a href="https://www.instagram.com/robins_instaswe/" className=" text-gray-400 h-6 w-6 hover:text-gray-600">
                                <FaSquareInstagram className="h-10 w-10" />
                            </a>
                        </li>
                        <li className="inline-block ">
                            <a href="https://www.linkedin.com/in/robin-vikstedt/" className=" text-gray-400 h-6 w-6 hover:text-gray-600">
                                <FaLinkedin className="h-10 w-10" />
                            </a>
                        </li>
                    </ul>
                </div>

            </section>

            {/* Contact Form Section */}
            <div className="lg:w-2/3 bg-white p-6 m-4 rounded-lg shadow-2xl">
                <h2 className="text-2xl font-semibold mb-6">Contact Me</h2>
                {isFormSent && (
                    <p className="text-green-600 mb-4">Thank you! Your form has been successfully sent.</p>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="firstName" className="block text-gray-600 text-sm font-medium mb-1">
                            First Name:
                        </label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder=''
                            className="w-full px-4 py-2 border rounded border-gray-300"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="lastName" className="block text-gray-600 text-sm font-medium mb-1">
                            Last Name:
                        </label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            placeholder=''
                            className="w-full px-4 py-2 border rounded border-gray-300"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-600 text-sm font-medium mb-1">
                            Email:
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder='example@gmail.com'
                            className={`w-full px-4 py-2 border rounded ${formErrors.email ? 'border-red-500' : 'border-gray-300'}`}
                            required
                        />
                        {formErrors.email && <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="phone" className="block text-gray-600 text-sm font-medium mb-1">
                            Phone (optional):
                        </label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder='070 123 45 67'
                            className="w-full px-4 py-2 border rounded border-gray-300"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="selectedObject" className="block text-gray-600 text-sm font-medium mb-1">
                            Select an Object:
                        </label>
                        <select
                            id="selectedObject"
                            name="selectedObject"
                            value={formData.selectedObject}
                            onChange={handleChange}
                            className={`w-full px-4 py-2 border rounded ${formErrors.selectedObject ? 'border-red-500' : 'border-gray-300'} bg-transparent`}
                            required
                        >
                            <option value="">-- Select --</option>
                            <option value="web">Development</option>
                            <option value="design">Connect</option>
                            <option value="other">Other</option>
                        </select>
                        {formErrors.selectedObject && (
                            <p className="text-red-500 text-xs mt-1">{formErrors.selectedObject}</p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="message" className="block text-gray-600 text-sm font-medium mb-1">
                            Message:
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder='. . .'
                            className="w-full px-4 py-2 border rounded border-gray-300"
                            rows="4"
                            required
                        ></textarea>
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
}
