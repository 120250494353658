import { NavLink, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { FaLinkedin, FaSquareInstagram, FaSquareFacebook } from "react-icons/fa6";

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const [title, setTitle] = useState('Vikstedt Title');
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/contact') {
            setTitle('Vikstedt - Contact');
        }
        else if (location.pathname === '/about') {
            setTitle('Vikstedt - About');
        }
        else if (location.pathname === '/projects') {
            setTitle('Vikstedt - Projects');
        }
        else {
            setTitle('Vikstedt');
        }
        document.title = title;
        return () => {
            document.title = 'Vikstedt';
        };
    }, [title, location]);

    return (
        <header className={`sticky top-0 flex items-center justify-center h-20 bg-gray-200 text-gray-800 shadow-xl p-4 ${isMenuOpen ? '' : 'delay-300 '}`}>
            <div className="flex flex-col md:flex-row justify-between lg:w-[80vw] sm:w-[90vw] w-full">
                <div className="flex justify-between items-center">
                    <NavLink to={'/'} className='flex flex-row items-center' onClick={closeMenu}>
                        <img src="/img/Logo1.png" alt="" className="lg:h-12 lg:w-14 sm:h-10 sm:w-12 h-8 w-8" />
                        <h1 className="font-serif md:text-3xl sm:text-2xl text-2xl italic font-medium tracking-wide ">ikstedt</h1>
                    </NavLink>

                    {/* Burger Button for Small Screens */}
                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="md:hidden ml-2 focus:outline-none z-10"
                    >
                        <svg className="h-6 w-6 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                        </svg>
                    </button>
                </div>

                {/* Navigation Links */}
                <nav className={`mt-4 md:mt-0 md:ml-4 ${isMenuOpen ? 'hidden md:flex' : 'hidden md:flex '}`}>
                    <ul className="flex flex-row items-center gap-4">
                        <NavLink
                            to={'/about'}
                            onClick={closeMenu}
                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "font-serif text-lg font-bold tracking-wide hover:text-gray-600 text-gray-600 focus:outline-none" : "font-serif text-lg font-bold transform duration-300 tracking-wide hover:text-gray-600 text-gray-400 focus:outline-none"
                            }
                        // className="font-serif text-lg font-bold tracking-wide hover:text-gray-600 text-gray-400 focus:outline-none"
                        >
                            About
                        </NavLink>
                        <NavLink
                            to={'/projects'}
                            onClick={closeMenu}
                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "font-serif text-lg font-bold tracking-wide hover:text-gray-600 text-gray-600 focus:outline-none" : "font-serif text-lg font-bold transform duration-300 tracking-wide hover:text-gray-600 text-gray-400 focus:outline-none"
                            }
                        >
                            Projects
                        </NavLink>
                        <NavLink
                            to={'/contact'}
                            onClick={closeMenu}
                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "font-serif text-lg font-bold tracking-wide hover:text-gray-600 text-gray-600 focus:outline-none" : "font-serif text-lg font-bold transform duration-300 tracking-wide hover:text-gray-600 text-gray-400 focus:outline-none"
                            }
                        >
                            Contact
                        </NavLink>

                        <ul className="list-none flex items-center">
                            <li className="inline-block mr-4">
                                <a href="https://www.facebook.com/profile.php?id=100068941629133" className=" text-gray-400 hover:text-gray-600 ">
                                    <FaSquareFacebook className="h-6 w-6" />
                                </a>
                            </li>
                            <li className="inline-block mr-4">
                                <a href="https://www.instagram.com/robins_instaswe/" className=" text-gray-400 h-6 w-6 hover:text-gray-600">
                                    <FaSquareInstagram className="h-6 w-6" />
                                </a>
                            </li>
                            <li className="inline-block">
                                <a href="https://www.linkedin.com/in/robin-vikstedt/" className=" text-gray-400 h-6 w-6 hover:text-gray-600">
                                    <FaLinkedin className="h-6 w-6" />
                                </a>
                            </li>
                        </ul>
                    </ul>
                </nav>

                {/* Mobile Navigation */}
                <nav className={`absolute top-20 left-0 right-0 bg-gray-200 text-gray-800 shadow-xl transition-opacity duration-700 ${isMenuOpen ? 'opacity-100 border-t border-gray-300' : 'opacity-0 pointer-events-none'}`}>
                    <ul className="flex flex-col items-center gap-4 py-4">
                        <NavLink
                            to={'/about'}
                            onClick={closeMenu}
                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "font-serif text-lg font-bold tracking-widetext-gray-600 focus:outline-none" : "font-serif text-lg font-bold transform duration-300 tracking-wide hover:text-gray-600 text-gray-400 focus:outline-none"
                            }
                        >
                            About
                        </NavLink>
                        <NavLink
                            to={'/projects'}
                            onClick={closeMenu}
                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "font-serif text-lg font-bold tracking-wide text-gray-600 focus:outline-none" : "font-serif text-lg font-bold transform duration-300 tracking-wide hover:text-gray-600 text-gray-400 focus:outline-none"
                            }
                        >
                            Projects
                        </NavLink>
                        <NavLink
                            to={'/contact'}
                            onClick={closeMenu}
                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "font-serif text-lg font-bold tracking-wide text-gray-600 focus:outline-none" : "font-serif text-lg font-bold transform duration-300 tracking-wide hover:text-gray-600 text-gray-400 focus:outline-none"
                            }
                        >
                            Contact
                        </NavLink>
                        <ul className="list-none flex gap-4">
                            <li className="inline-block ">
                                <a href="https://www.facebook.com/profile.php?id=100068941629133" className=" text-gray-400 hover:text-gray-600 ">
                                    <FaSquareFacebook className="h-6 w-6" />
                                </a>
                            </li>
                            <li className="inline-block ">
                                <a href="https://www.instagram.com/robins_instaswe/" className=" text-gray-400 h-6 w-6 hover:text-gray-600">
                                    <FaSquareInstagram className="h-6 w-6" />
                                </a>
                            </li>
                            <li className="inline-block ">
                                <a href="https://www.linkedin.com/in/robin-vikstedt/" className=" text-gray-400 h-6 w-6 hover:text-gray-600">
                                    <FaLinkedin className="h-6 w-6" />
                                </a>
                            </li>
                        </ul>
                    </ul>
                </nav>
            </div>
        </header>
    );
}
