import { Link } from "react-router-dom";

import { FaLinkedin , FaSquareInstagram , FaSquareFacebook  } from "react-icons/fa6";

export default function Footer() {
    return (
        <footer className="bg-gray-200 text-gray-800 py-12">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div>
                        <h3 className="text-lg font-semibold mb-4">About</h3>
                        <p className="text-sm">This is my portfolio website.</p>
                    </div>
                    <div>
                        <Link to='/contact' className="text-lg font-semibold hover:border-b-2">Contact</Link>
                        <p className="text-sm mt-4">Email: robin@vikstedt.se</p>
                        {/* <p className="text-sm">Phone: +46 (0)70-123 45 67</p> */}
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4">Follow</h3>
                        <ul className="list-none">
                            <li className="inline-block mr-4">
                                <a href="https://www.facebook.com/profile.php?id=100068941629133" className=" text-gray-600 hover:text-gray-800 ">
                                    <FaSquareFacebook  className="h-6 w-6" />
                                </a>
                            </li>
                            <li className="inline-block mr-4">
                                <a href="https://www.instagram.com/robins_instaswe/" className=" text-gray-600 h-6 w-6">
                                    <FaSquareInstagram  className="h-6 w-6" />
                                </a>
                            </li>
                            <li className="inline-block">
                                <a href="https://www.linkedin.com/in/robin-vikstedt/" className=" text-gray-600 h-6 w-6">
                                    <FaLinkedin  className="h-6 w-6" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr className="border-gray-800 my-8" />
                <div className="text-center">
                    <p className="text-sm">&copy; 2024 Vikstedt. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}
