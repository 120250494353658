

export default function Home() {


    
    return(
        <main className="flex items-center justify-center h-screen">

        </main>
    )
}