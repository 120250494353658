import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function NoPage() {
    const location = useLocation();

    return (
        <main className="flex items-center justify-center h-screen bg-gray-100">
            <div className="text-center p-8 bg-white shadow-md rounded-md">
                <h1 className="text-6xl font-bold text-red-500 mb-4">404</h1>
                <h2 className="text-2xl text-gray-800 mb-4">Oops! Page not found.</h2>
                <p className="text-lg text-gray-600 mb-8">
                    The requested URL <code className='font-bold'>{location.pathname}</code> does not exist.
                </p>
                <Link to="/" className="text-indigo-700 hover:underline text-lg">
                    Go back to the home page
                </Link>
            </div>
        </main>
    );
}